import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const esignHtmlContent = (
  <div className="col-md-12 mx-auto">
    <h3 className="display-5">
      CONSENT TO ELECTRONIC TRANSACTIONS AND DISCLOSURES
    </h3>
    <p className="lead">
      Because the Nextate financial platform operates only on the Internet,
      whether you choose to participate on our platform as a borrower, guarantor
      or in any other manner, it is necessary for you to consent to transact
      business with us online and electronically. As a lender, we also need you
      to consent to our giving you certain disclosures electronically, either
      via our Site or to the email address you provide to us. By agreeing to the
      Terms of Use, you agree to receive electronically all documents,
      communications, notices, contracts, and agreements arising from or
      relating to your use of the Site and Service, including any loans you may
      request or receive or guarantee, your registration as a borrower or
      guarantor on our Site, your use of this Service, and the servicing of your
      loan, if funded, as either a borrower or guarantor member of Trustic
      (each, a "Disclosure"), from us, whether we are acting in the capacity as
      trustee or otherwise.
    </p>
    <p className="lead">
      The decision to do business with us electronically is yours. This document
      informs you of your rights concerning Disclosures.
    </p>
    <p className="lead">
      <b>CUSTOMER COMMUNICATIONS:</b> By accepting these Terms of Use, you
      expressly consent to be contacted by us, our agents, representatives,
      affiliates, or anyone calling on our behalf for any and all purposes, at
      any telephone number, or physical or electronic address you provide or at
      which you may be reached. You agree we may contact you in any way,
      including SMS messages (including text messages), calls using prerecorded
      messages or artificial voice, and calls and messages delivered using auto
      telephone dialing system or an automatic texting system. Automated
      messages may be played when the telephone is answered, whether by you or
      someone else. In the event that an agent or representative calls, he or
      she may also leave a message on your answering machine, voice mail, or
      send one via text.
    </p>
    <p className="lead">
      You consent to receive SMS messages (including text messages), calls and
      messages (including prerecorded and artificial voice and autodialed) from
      us, our agents, representatives, affiliates or anyone calling on our
      behalf at the specific number(s) you have provided to us, or numbers we
      can reasonably associate with your account (through skip trace, caller ID
      capture or other means), with information or questions about your
      application, loan and/or account. You certify, warrant and represent that
      the telephone numbers that you have provided to us are your numbers and
      not someone else's. You represent that you are permitted to receive calls
      at each of the telephone numbers you have provided to us. You agree to
      alert us whenever you stop using a particular telephone number.
    </p>
    <p className="lead">
      <b>
        TELEMARKETING CALLS: If you have provided consent for telemarketing
        calls, you agree that (i) we, our agents, representatives, affiliates,
        third parties, and anyone calling on our behalf may call, email or SMS
        messages (including text messages) you at the numbers and addresses you
        have provided for purposes of describing goods and services that may be
        of interest to you, offered by us, our affiliates and/or third parties;
        (ii) agree these calls, text and email messages may be made using an
        automatic dialing or email system technology and/or involve prerecorded
        and/or artificial voice messaging. Your consent, if provided, will be
        effective even if the number you have provided is registered on any
        state or federal Do-Not-Call (DNC) list. This consent for telemarketing
        calls shall remain in effect until you revoke it. Your consent to
        telemarketing calls may be revoked by informing the telemarketing
        representative, or any other method, which ensures we receive the
        revocation.
      </b>
    </p>
    <p className="lead">
      <b>
        Your consent to telemarketing calls is not a condition of obtaining your
        loan.
      </b>
    </p>
    <p className="lead">
      You also understand that your cellular or mobile telephone provider will
      charge you according to the type of plan you carry.
    </p>
    <p className="lead">
      <b>Opt-Out:</b> You can revoke your consent by contacting us via email at
      compliance@trustic.com or by calling Support at 888-429-0480. You may also
      reach us in writing at the following address: Trustic, 111 W Jackson Blvd,
      Suite 1700, Chicago, IL 60604, Attention: Opt- out.
    </p>
    <p className="lead">
      <b>CALL RECORDING AND MONITORING:</b> You consent to the recording and
      monitoring, for quality assurance, training, risk management and/or
      collection purposes, of any call that you place with us (or our agents,
      representatives, affiliates, third parties or anyone calling on our
      behalf) or that we (or our agents, representatives, affiliates, third
      parties or anyone calling on our behalf) place to you.
    </p>
    <p className="lead">
      Electronic Communications. Any Disclosures will be provided to you
      electronically through nextate.com either on our Site or via electronic
      mail to the email address you provided. If you require paper copies of
      such Disclosures, you may sign in to your account on our Site and print
      the documents desired or you may write to us at the mailing address
      provided below and a paper copy will be sent to you at a cost of up to
      $5.00 per document requested. A request for a paper copy of any Disclosure
      will not be considered a withdrawal of your consent to receive Disclosures
      electronically. We may discontinue electronic provision of Disclosures at
      any time in our sole discretion.
    </p>
    <p className="lead">
      Scope of Consent. Your consent to receive Disclosures and transact
      business electronically, and our agreement to do so, applies to any
      transactions to which such Disclosures relate between you and Trustic.
      Your consent will remain in effect for so long as you are a User and, if
      you are no longer a User, will continue until such a time as all
      Disclosures relevant to transactions that occurred while you were a User
      have been made.
    </p>
    <p className="lead">
      Consenting to Do Business Electronically. Before you decide to do business
      electronically with Trustic, you should consider whether you have the
      required hardware and software capabilities described below.
    </p>
    <p className="lead">
      Hardware and Software Requirements. In order to access and retain
      Disclosures electronically, you must satisfy the following computer
      hardware and software requirements: access to the Internet; an email
      account and related software capable of receiving email through the
      Internet; supported Web browsing software (Chrome version 32.0 or higher,
      Firefox version 26.0 or higher, Internet Explorer version 8.0 or higher,
      or Safari version 7.0 or higher); and hardware capable of running this
      software. To ensure access and optimal printing of your loan documents in
      PDF format, you must have Adobe Reader, that can be downloaded for free.
    </p>
    <p className="lead">
      <b>Additional Mobile Technology Requirements.</b> If you are accessing our
      site and the Disclosures electronically via a mobile device (such as a
      smart phone, tablet, and the like), in addition to the above requirements
      you must make sure that you have software on your mobile device that
      allows you to print and save the Disclosures presented to you during the
      application process. These applications can be found for most mobile
      devices in the device's respective "app store". If you do not have these
      capabilities on your mobile device, please access our site through a
      device that provides these capabilities.
    </p>
    <p className="lead">
      <b>Withdrawing Consent.</b> You may withdraw your consent to receive
      Disclosures electronically by contacting us at the address below. However,
      once you have withdrawn your consent you will not be able to post loan
      requests on our Site or register as a guarantor. If you have a pending
      loan request on our Site we will terminate it and remove it from our
      system. If you are acting as a potential guarantor for a loan, your
      guarantor nomination will be cancelled and the borrower will have to
      nominate a different guarantor. If you have already received a loan that
      has been funded, all previously agreed to terms and conditions will remain
      in effect, and we will send Disclosures to your verified home address
      provided during registration. If you are a guarantor on a loan, all
      previously agreed to terms and conditions will remain in effect, and we
      will send Disclosures to your verified home address provided during
      registration.
    </p>
    <p className="lead">
      How to Contact Us regarding Electronic Disclosures. You can contact us via
      email at compliance@trustic.com or by calling Support at 888-429-0480. You
      may also reach us in writing at the following address: Trustic, 111 W
      Jackson Blvd, Suite 1700, Chicago, IL 60604, Attention: Opt- out:
      Consents.
    </p>
    <p className="lead">
      You will print a copy of this Agreement for your records and you agree and
      acknowledge that you can access, receive and retain all Disclosures
      electronically sent via email or posted on the Site.
    </p>
    <p className="lead">
      Change in Your Contact Information. You will keep us informed of any
      change in your email or home mailing address so that you can continue to
      receive all Disclosures in a timely fashion. You must notify us of the
      change by sending an email to{" "}
      <a href="mailto:support@trustic.com">support@trustic.com</a> or calling
      888-429-0480. You also agree to update your registered residence address
      and telephone number on the Site if they change.
    </p>
    <p className="lead">
      If you are acting as a guarantor for a loan, you also agree to notify us
      of any change to your home address via email or telephone using the
      contact information provided above.
    </p>
  </div>
)

const Esign = () => (
  <Layout
    headerComponent={
      <header className="header pt-8 pb-4 bg-dark text-white text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <h1>ESIGN Act Consent</h1>
              <p className="lead-2 opacity-90 mt-6">
                Last update: May 20, 2021
              </p>
            </div>
          </div>
        </div>
      </header>
    }
  >
    <section className="section py-7">
      <div className="container">
        <div className="row">{esignHtmlContent}</div>
      </div>
    </section>
  </Layout>
)

export default Esign

export { esignHtmlContent }
